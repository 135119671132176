import { Button, DatePicker, Form, Input, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TAdminInvitee, TBusinessUserPii } from 'src/graphql'
import { useAsRef, useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { EBusinessTypeOptions } from 'src/interfaces'
import { MessageService } from 'src/services'
import { CountriesStore } from 'src/store'

type FieldType = {
  fullLegalName?: string
  dbaName?: string
  type?: string
  countryOfIncorporation?: string
  dateOfIncorporation?: dayjs.Dayjs
  incorporationNumber?: string
  legalAddress?: string
  primaryAddress?: string
  website?: string
  email?: string
  phone?: string
}

export const DialogEditBusinessProfile: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TBusinessUserPii
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const countriesOptions = useBehaviorMapper(CountriesStore.countriesOptions$)
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm()
  useEffect(() => {
    if (!invisible) {
      form.setFieldsValue({
        fullLegalName: user?.fullLegalName || '',
        dbaName: user?.doingBusinessAsName || '',
        type: user?.businessType || '',
        countryOfIncorporation: user?.incorporationCountry || '',
        dateOfIncorporation: user?.dateOfIncorporation ? dayjs(new Date(String(user?.dateOfIncorporation)?.split('T')[0])) : undefined,
        incorporationNumber: user?.incorporationNumber || '',
        legalAddress: user?.legalAddress || '',
        primaryAddress: user?.primaryAddress || '',
        website: user?.website || '',
        email: user?.email || '',
        phone: user?.phone || ''
      })
    }
  }, [invisible, user, form])

  const submit = useCallback((values: FieldType) => {
    setLoading(true)
    from(PiiApi.adminUpdateBusinessProfile({
      input: {
        id: user?.id || '',
        fullLegalName: values.fullLegalName!,
        dbaName: values.dbaName!,
        type: values.type! as any,
        countryOfIncorporation: values.countryOfIncorporation!,
        dateOfIncorporation: values.dateOfIncorporation?.format('YYYY-MM-DD') || '',
        incorporationNumber: values.incorporationNumber!,
        legalAddress: values.legalAddress!,
        primaryAddress: values.primaryAddress!,
        website: values.website!,
        email: values.email!,
        phone: values.phone!
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Edit business profile successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [unsubscribe$, afterCloseRef, user?.id])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit'}
      </Button>

      <Modal
        title={props.title || 'Edit Business Profile'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => setInvisible(true)}
        width={800}
      >
        {!invisible && (
          <Form
            form={form}
            name="edit-business-profile"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 16 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item
              label="Full Legal Name"
              name="fullLegalName"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Doing Business As (DBA) name"
              name="dbaName"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Type of Business"
              name="type"
              rules={[{ required: true }]}
            >
              <Select allowClear options={EBusinessTypeOptions}/>
            </Form.Item>

            <Form.Item
              label="Country of incorporation"
              name="countryOfIncorporation"
              rules={[{ required: true }]}
            >
              <Select allowClear options={countriesOptions}/>
            </Form.Item>

            <Form.Item
              label="Date of Entity Incorporation / Establishment"
              name="dateOfIncorporation"
              rules={[{ required: true }]}
            >
              <DatePicker value={form.getFieldValue('')} placeholder="" style={{ width: '100%' }}/>
            </Form.Item>

            <Form.Item
              label="Incorporation Number / Business Registration Number"
              name="incorporationNumber"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Full Legal (Registered) Address"
              name="legalAddress"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Full Primary Business Address"
              name="primaryAddress"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Official Website"
              name="website"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Official e-mail address"
              name="email"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Official Phone number"
              name="phone"
              rules={[
                { required: true },
                {
                  pattern: /^\+?\d+$/,
                  message: 'Official Phone number is invalid'
                }
              ]}
            >
              <Input/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
