const DEF_CONVERT_DECIMALS = 9
const DEF_ROUND_DECIMALS = 6

export class NumberUtils {
  static ensure(value?: number | string | null, defaultValue = 0): number {
    if (value === undefined || value === null) {
      return this.ensure(defaultValue)
    }

    value = Number(value)

    if (Number.isNaN(value)) {
      return this.ensure(defaultValue)
    }

    return value
  }

  /**
   * Format a Number by locales, default en-EN
   */
  static formatNumber(
    value?: number | string | null,
    options?: Intl.NumberFormatOptions & {
      locales?: string | string[]
    }
  ) {
    if (value === null || value === undefined) {
      return ''
    }
    const { locales } = options || {}
    const num = typeof options?.maximumFractionDigits === 'number'
      ? NumberUtils.floorDecimal(+value, options?.maximumFractionDigits)
      : +value

    return new Intl.NumberFormat(locales || 'en-EN', options).format(num)
  }

  static formatDecimal(value: Parameters<typeof NumberUtils.formatNumber>[0], decimals = 2) {
    return NumberUtils.formatNumber(value, {
      minimumFractionDigits: Math.max(0, decimals),
      maximumFractionDigits: Math.max(0, decimals)
    })
  }

  static formatWholeNumber(value?: number | string | null) {
    if (value === null || value === undefined) return ''

    const numberString = value.toString()

    const decimalPlaces = numberString.includes('.')
      ? numberString.split('.')[1].length
      : 0

    return NumberUtils.formatDecimal(value, decimalPlaces)
  }

  static floorDecimal(number = 0, decimalPlaces = 2) {
    const parts = (Number(number) || 0).toString().split('.')
    const integerPart = parts[0]
    const decimalPart = (parts[1] || '').slice(0, decimalPlaces)
    return Number(`${integerPart}.${decimalPart}`)
  }

  static numberToBigInt(num?: number | string | null, options: { decimal?: number } = {}) {
    if (!num || !Number(num)) {
      return BigInt(0)
    }
    const [intPart, decimalPart = ''] = num.toString().split('.')
    const { decimal = DEF_CONVERT_DECIMALS } = options
    const keepDps = Math.min(decimal, decimalPart.length)
    const int = BigInt(intPart + decimalPart.slice(0, keepDps))
    const additionDps = Math.max(decimal - decimalPart.length, 0)
    return int * BigInt('1' + '0'.repeat(additionDps))
  }

  static bigIntToNumber(num: bigint, options: {
    currencyDecimal?: number
    roundDecimals?: number
  } = {}) {
    const { currencyDecimal = DEF_CONVERT_DECIMALS, roundDecimals = DEF_ROUND_DECIMALS } = options
    const str = String((num < 0 ? -num : num) / BigInt('1' + '0'.repeat(currencyDecimal - roundDecimals)))
    const roundedStr = (
      str.length >= roundDecimals
        ? str
        : Array.from({ length: roundDecimals - str.length }, () => '0').join('') + str
    ).replace(new RegExp(`([0-9]{${roundDecimals}})$`), '.$1')
    return num < 0 ? -Number(roundedStr) : Number(roundedStr)
  }

  static sum(a: number | string = 0, b: number | string = 0) {
    const biDecimals = Math.max(
      String(a).split('.')[1]?.length || 0,
      String(b).split('.')[1]?.length || 0
    )
    const biA = NumberUtils.numberToBigInt(a, { decimal: biDecimals })
    const biB = NumberUtils.numberToBigInt(b, { decimal: biDecimals })
    return NumberUtils.bigIntToNumber(biA + biB, { currencyDecimal: biDecimals, roundDecimals: biDecimals })
  }

  static subtract(a: number | string = 0, b: number | string = 0) {
    const biDecimals = Math.max(
      String(a).split('.')[1]?.length || 0,
      String(b).split('.')[1]?.length || 0
    )
    const biA = NumberUtils.numberToBigInt(a, { decimal: biDecimals })
    const biB = NumberUtils.numberToBigInt(b, { decimal: biDecimals })
    return NumberUtils.bigIntToNumber(biA - biB, { currencyDecimal: biDecimals, roundDecimals: biDecimals })
  }

  static multiply(a: number | string = 0, b: number | string = 0) {
    const biDecimals = Math.max(
      String(a).split('.')[1]?.length || 0,
      String(b).split('.')[1]?.length || 0
    )
    const outputBigInt = NumberUtils.numberToBigInt(a, { decimal: biDecimals }) * NumberUtils.numberToBigInt(b, { decimal: biDecimals })
    return NumberUtils.bigIntToNumber(outputBigInt, {
      currencyDecimal: biDecimals * 2,
      roundDecimals: biDecimals * 2
    })
  }

  static divide(dividend: number | string = 0, divisor: number | string = 0) {
    if (!Number(divisor)) {
      return 0
    }
    const biDecimals = Math.max(
      String(dividend).split('.')[1]?.length || 0,
      String(divisor).split('.')[1]?.length || 0
    )
    const dividendBI = NumberUtils.numberToBigInt(dividend, { decimal: biDecimals })
    const divisorBI = NumberUtils.numberToBigInt(divisor, { decimal: biDecimals })
    return NumberUtils.ensure(Number(dividendBI) / Number(divisorBI))
  }

  static parseInputValue(value: string | number, options: {
    integers?: number
    decimals?: number
  } = {
    integers: 21,
    decimals: 2
  }) {
    if (!value || value === '0') {
      return '0'
    }
    const numbers = String(value).replace(/[^0-9.]/g, '')
    const [integer, decimal] = numbers.split('.')
    const integerPart = integer.replace(/^0+/, '').slice(0, options.integers || 9)
    const decimalPart = decimal ? decimal.slice(0, options.decimals) : ''
    return decimalPart
      ? `${integerPart || 0}.${decimalPart}`
      : numbers.includes('.')
        ? `${integerPart || 0}.`
        : integerPart
          ? integerPart || '0'
          : ''
  }

  static getValueNumber<T = unknown>(value: any, defaultValue: T = 0 as T) {
    return typeof value === 'number' ? NumberUtils.formatNumber(value) : defaultValue
  }
}
