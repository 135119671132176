import { Button, Form, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { TAccountKolRewards } from 'src/graphql'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

const schema = object().shape({
  paidAmount: string().typeError('Amount invalid').required('Amount is required'),
  comment: string().typeError('Comment invalid')
})

export const DialogAmountPaid: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  accountKolReward?: TAccountKolRewards
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  accountKolReward,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [formData, setFormData] = useState({
    paidAmount: '',
    comment: ''
  })

  const onChange = useCallback(
    <T extends keyof typeof formData>(
      field: T,
      value: (typeof formData)[T]
    ) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value
      }))
    },
    []
  )

  const { validate, reset: resetValidation, errors } = useValidation({
    data: formData,
    schema
  })

  const reset = useCallback(() => {
    setFormData({
      paidAmount: '',
      comment: ''
    })
    resetValidation()
  }, [resetValidation])

  useEffect(() => {
    reset()
  }, [invisible, reset])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid, value } = await validate()

    if (!isValid || !value) {
      return
    }

    if (
      !accountKolReward ||
      !accountKolReward.accountID ||
      !accountKolReward?.epoch?.epoch ||
      !value.paidAmount
    ) {
      return MessageService.error('Something went wrong!')
    }

    const input: Parameters<typeof KolTrackerApi.updateClientPaidAmount>[0]['input'] = {
      comment: value.comment,
      epoch: accountKolReward?.epoch?.epoch,
      id: accountKolReward?.accountID,
      paidAmount: Number(value.paidAmount)
    }

    setLoading(true)
    from(KolTrackerApi.updateClientPaidAmount({ input }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Paid amount changed successfully!')
        setInvisible(true)
        reset()
        afterCloseRef.current?.(true)
      })
  }, [unsubscribe$, accountKolReward, afterCloseRef, validate, reset])

  return (
    <>
      <Button
        type="link"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit amount paid'}
      </Button>

      <Modal
        title={props.title || 'Edit amount paid'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            name="change-amount-paid"
            labelCol={{ span: 7 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
          >
            <Form.Item
              label="Amount Paid"
              name="paidAmount"
              rules={[{ required: true }]}
            >
              <Input
                value={formData.paidAmount}
                onChange={(e) => {
                  const value = e.target.value
                  if (!value || /^\d*\.?\d{0,2}$/.test(value)) {
                    onChange('paidAmount', value)
                  }
                }}
                status={errors.hasError('paidAmount') ? 'error' : undefined}
              />
              <ErrorMessage>{errors.getError('paidAmount')}</ErrorMessage>
            </Form.Item>
            <Form.Item
              label="Comment"
              name="comment"
            >
              <Input.TextArea
                value={formData.comment}
                onChange={(e) => onChange('comment', e.target.value)}
                status={errors.hasError('comment') ? 'error' : undefined}
              />
              <ErrorMessage>{errors.getError('comment')}</ErrorMessage>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
