import { ArrowLeftOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { TAccountKolRewards } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { DialogAmountPaid } from 'src/partials'
import { EPaths } from 'src/router'
import { BreadcrumbService, MessageService, TzService } from 'src/services'
import { NumberUtils } from 'src/utils'

export const InstPaymentDetails: FC = () => {
  const { accountID } = useParams<{ accountID: string }>()

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.BD_INSTITUTION_TRACKER,
        label: 'Inst. Tracker'
      },
      {
        route: [EPaths.BD_INST_PAYMENT_DETAILS, { accountID }],
        label: accountID
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [accountID])

  const unsubscribe$ = useUnsubscribe()
  const [accountKolRewards, setAccountKolRewards] = useState<TAccountKolRewards[]>([])

  const fetchAccountKolRewards = useCallback(() => {
    if (!accountID) return
    from(KolTrackerApi.accountKolRewards({ where: { accountID } }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((res) => {
        res && setAccountKolRewards(res as TAccountKolRewards[])
      })
  }, [unsubscribe$, accountID])

  useEffect(() => {
    fetchAccountKolRewards()
  }, [fetchAccountKolRewards])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <Link to={EPaths.BD_INSTITUTION_TRACKER} className="fx fx-ai-center gap-3 black">
          <ArrowLeftOutlined/>
          <span className="label-1">Inst. Tracker</span>
        </Link>

        {/* <div className="fx fx-ai-center gap-2 fx-jc-end">
          <Input.Search
            allowClear
            placeholder="Search by email"
            style={{ flex: '0 0 200px' }}
          />
          <Button type="primary" disabled>
            Clear
          </Button>
        </div> */}
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        dataSource={accountKolRewards}
        columns={[
          {
            title: 'Epoch',
            dataIndex: 'epoch',
            key: 'epoch',
            render: (epoch) => epoch.epoch
          },
          {
            title: 'Epoch Earnings (USD)',
            dataIndex: 'earningAmount',
            key: 'earningAmount',
            render: (val: TAccountKolRewards['earningAmount']) => NumberUtils.formatDecimal(NumberUtils.divide(val, 1e6))
          },
          {
            title: 'Amount Due',
            dataIndex: 'dueAmount',
            key: 'dueAmount',
            render: (val: TAccountKolRewards['dueAmount']) => NumberUtils.formatDecimal(NumberUtils.divide(val, 1e6))
          },
          {
            title: 'Amount Paid',
            dataIndex: 'paidAmount',
            key: 'paidAmount',
            render: (val: TAccountKolRewards['paidAmount'], record: TAccountKolRewards) => (
              <div className="fx fx-ai-center gap-2">
                <DialogAmountPaid
                  accountKolReward={record}
                  afterClose={(fresh) => fresh && fetchAccountKolRewards()}
                >
                  {val && NumberUtils.formatDecimal(val)}
                </DialogAmountPaid>
              </div>
            )
          },
          {
            title: 'Last Updated',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (value: TAccountKolRewards['updateTime']) => value
              ? TzService.format(value, 'HH:mm YYYY-MM-DD')
              : '--'
          },
          {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment'
          }
        ]}
        tableLayout="fixed"
        scroll={{ x: true }}
      />
    </section>
  )
}
